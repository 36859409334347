// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

// IMPORT OPEN SANS 

$OpenSansPath: "fonts" !default;
$OpenSansVersion: "1.101" !default;

@mixin fontdef($OpenSansPath, $OpenSansVersion, $FontType: "Regular") {
	src: url("#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.woff2?v=#{$OpenSansVersion}") format("woff2"),
		url("#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.woff?v=#{$OpenSansVersion}") format("woff");
}

/* BEGIN Regular */
@font-face {
	font-family: "Open Sans";
	@include fontdef($OpenSansPath, $OpenSansVersion, "Regular");
	font-weight: normal;
	font-style: normal;
}
/* END Regular */

/* BEGIN Bold */
@font-face {
	font-family: "Open Sans";
	@include fontdef($OpenSansPath, $OpenSansVersion, "Bold");
	
	font-weight: bold;
	font-style: normal;
}
/* END Bold */

/* BEGIN Light */
@font-face {
	font-family: "Open Sans";
	@include fontdef($OpenSansPath, $OpenSansVersion, "Light");
	font-weight: 300;
	font-style: normal;
}
/* END Light */

/* BEGIN Italic */
@font-face {
	font-family: "Open Sans";
	@include fontdef($OpenSansPath, $OpenSansVersion, "Italic");
	font-weight: normal;
	font-style: italic;
}
/* END Italic */

/* BEGIN Playfair Display */
@font-face {
	font-family: "Playfair Display";
	src: url("fonts/playfair-display-regular/playfair-display-regular.woff2") format("woff2"),
		 url("fonts/playfair-display-regular/playfair-display-regular.woff") format("woff"),
		 url('fonts/playfair-display-regular/playfair-display-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('fonts/playfair-display-regular/playfair-display-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
	font-weight: normal;
	font-style: normal;
}
/* END Playfair Display */

$primary: #F8AA0D; 

$oranje: #F8AA0D;
$paars: #8B4471;
$groen: #5AB89C;
$rood: #CA4C36;
$blauw: #627bbd;

$border-radius-small: 20px;
$border-radius-big: $border-radius-small*2;

