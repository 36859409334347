footer {
    padding: 4rem 0;
    // margin-top: 20px;
    
    .container > * {
      z-index: 100;
      position: relative;
      color: $paars;
      h4 {
        padding-bottom: 1rem;
        font-size: 30px;
        @include media-breakpoint-only(md){
          min-height: 90px;
        }
      }
      p {
        margin-bottom: 0;
        font-weight: 300;
      }
      ul {
        list-style: none;
        padding-inline-start: 0;
        li {
          line-height: 1.5;
        }
      }
      a {
        color: $paars;
        font-size: 18px;
        font-weight: 300;
      }
      a:hover {
          color: white;
      }
    .sm-icon {
        display: inline-block;
        width:35px; height: 35px;
        background: $paars;
        &:hover {
            background: white;
        }
    }  
    .facebook {
        mask-image: url('./img/facebook.svg');
    }
    .linkedin {
        mask-image: url('./img/linkedin.svg');
    }
    }
    .snelmenu {
      .dropdown-toggle {
        position: relative;
      }
      .dropdown-toggle::after {
        border: none;
        mask-image: url('./img/down-arrow.svg');
        background-color: $paars;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 8px;
        left: 100px;
      }
      .dropdown-menu {
        border-radius: 0;
        padding: 0;
        background: $oranje;
        border: none;
        a {
          font-size: 12px;
        }
      }
      .dropdown-item {
        padding: 0.5rem 1rem;
        &:hover {
          background: $paars;
          color: $oranje;
          border-radius: 0;
        }
      }
      .nav-link {
        width: 150px;
        background: $oranje;
        padding-left: 10px !important;
        color: $paars !important;
      }
      // .dropdown-menu{
      //   transform: translate3d(5px, 35px, 0px)!important;
      // }
      ul li {
        line-height: 1.5;
      }
    }
  }

  .disclaimer {
    background: $paars;
    text-align: center;
    p {
      padding: 0.5rem 0;
      margin:0;
      color: white;
      font-size: small;
      @media screen and (max-width: 768px) {
        display: block !important;
        padding: 0.5rem 20px;
      }
    }
  }