header {
  .top-bar{
    background: $paars;
    @media screen and (max-width: 991px) {
      display: none;
    }
    .navbar{
      padding: 0;
    }
    .nav-item:last-child a {
      padding-right: 0;
    }
    ul {
      display: flex;
      flex-direction: row;
      margin: auto 0;
    }
    li {
      list-style: none;
    }
    a {
      color: $oranje;
      font-size: 19px;
      padding: 0.7rem 1rem; 
      &:hover {
        color: white;
      }
    }
    &.fixed {
      a {
        padding: 8px 1rem;
      }
    }
  }
  #mid-bar {
    margin-top: 50px;
    background: white;
    transition: all ease-in-out 0.3s;
    
    @media screen and (max-width: 991px) {
      display: none;
    }
    .logo {
      padding: 20px 0;
    }
    .links {
align-items: center;
      .row {
        height: 100%;
      }
      .col-4 {
        display: flex;
        align-items: center;
      }
      .kennis {
        @media screen and (min-width: 1600px) {
          margin-left: 33.33333%;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
        @media screen and (max-width: 1600px) {
          margin-left: 25%;
          flex: 0 0 41.33333%;
          max-width: 41.33333%;
        }
        @media screen and (max-width: 1200px) {
          margin-left: 20%;
          flex: 0 0 46.33333%;
          max-width: 46.33333%;
        } 
      }
      .brochure {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        a {
          color: #212529;
          &:hover {
            color: $oranje;
          }
          @media screen and (min-width: 991px) and (max-width:1200px) {
            color: $oranje;
          }
        }
      }
      img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        @media screen and (min-width: 991px) and (max-width:1200px) {
          display:none;
        }
      }
      p {
        display: inline-block;
        margin:0;
      }
    }
    &.fixed {
      position: fixed;
      top:0;
      left: 0;
      width: 100%;
      z-index: 999;
      margin-top: 44px;
      height: 99px;
      .d-lg-block {
        display: none !important;
      }
      .d-lg-none {
        display: block!important;
      }

    }
  }
  #main-nav{
    transition: all ease-in-out 0.3s;
    background: $oranje;
    #navbarNavDropdown {
      justify-content: center;
    }
    .navbar {
      padding: 0;
    }
    .nav-link {
      color: $paars;
      padding-left:1.5rem;
      padding-right:1.5rem;
      @media screen and (max-width: 1600px) {
        padding-left:1rem;
        padding-right:1rem;
      }
    }
    li {
      // font-family: "Playfair Display";
      color: $paars !important;
      font-size: 24px;
      font-weight: normal;
      @media screen and (max-width: 1204px) {
        font-size: 20px;
      }
    }
    
    @media screen and (min-width: 991px ) {
      li.active a, li a:hover{
        background: $oranje;
        color: $paars;
      }
      li:hover .nav-link, li.current-menu-item .nav-link {
        background: white;
        color: $paars;
      }
      li:nth-child(2):hover .nav-link, li:nth-child(2).current-menu-item .nav-link {
        background: $paars;
        color: white
      }
      li:nth-child(3):hover .nav-link, li:nth-child(3).current-menu-item .nav-link {
        background: $groen;
        color: white
      }
      li:nth-child(4):hover .nav-link, li:nth-child(4).current-menu-item .nav-link {
        background: $rood;
        color: white
      }
      li:nth-child(5):hover .nav-link, li:nth-child(5).current-menu-item .nav-link {
        background: $blauw;
        color: white
      }
    }
    @media screen and (max-width:991px) {
      li.active a, li a:hover{
        background: $paars;
        color: $oranje;
      }
    }
    .dropdown-menu {
      border-radius: 0;
      margin: 0;
      border: none;
      padding: 0;
      background: $rood;
        .dropdown-item {
          color: white !important;
          padding: .4rem 1.5rem;
        }
        .dropdown-item:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        .dropdown-item:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        a:hover, .active a {
          background: $oranje !important;
          color: $rood !important;
        }
    }
    .dropdown-toggle {
      position: relative;
    }
    .dropdown-toggle::after {
      border: none;
      mask-image: url('../img/down-arrow.svg');
      background-color: $paars;
      position: absolute;
      width: 25px;
      height: 25px;
      top: 18px;
      }
    
    .navbar-toggler {
      float: right;
    }
    img {
      margin: 0.75rem;
      height: 45px;
      
    }
    &.fixed {
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 999;
      @media screen and (min-width: 1400px) {
        top:143px;
      }
      @media screen and (max-width: 1400px) {
        top: 130px;
      }
      @media screen and (max-width: 1200px) {
        top: 125px;
      }
      @media screen and (max-width: 991px) {
        top: 0 !important;
        margin-top: 0;
      }
    }
  }
}

// MOBILE 

.navbar-light .navbar-toggler-icon {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$oranje}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");
}

.navbar-light .navbar-toggler {
  border: none;
  outline: none;
}

@include media-breakpoint-down(md) {
  #main-nav {
    .navbar {
      position: fixed;
      background: white;
      width: 100%;
      z-index: 1500;
      box-shadow: 0 .15rem .15rem rgba(0,0,0,.15)!important;
      left: 0; top: 0;
    }
    .navbar-collapse {
        position: fixed;
        top: 62px;
        left: 0;
        text-align: center;
        padding: 15px;
        width: 100%;
        background: $oranje;
    }
    #main-menu {
      font-family: "Playfair Display";
    }
    .logged-in .navbar-collapse {
      top: 94px;
    }
    .navbar-collapse.collapsing {
        height: auto;
        -webkit-transition: left 0.3s ease;
        -o-transition: left 0.3s ease;
        -moz-transition: left 0.3s ease;
        transition: left 0.3s ease;
        left: -100%;
    }
    .navbar-collapse.show {
        left: 0;
        -webkit-transition: left 0.3s ease-in;
        -o-transition: left 0.3s ease-in;
        -moz-transition: left 0.3s ease-in;
        transition: left 0.3s ease-in;
    }
    .logo img {
      height: 42px;
      padding-left: 15px;
    }
    .dropdown-item, .nav-link  {
      color: white !important;
      font-size: 1.5rem !important;
    }
    .dropdown-menu {
      background: transparent;
      border: none;
      padding: 15px;
      text-align: center;
      .dropdown-item:focus, .dropdown-item:hover {
        background: $paars;
      }
    } 
  }
}