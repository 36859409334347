.jumbotron {
  height: 450px;
  padding: 0;
  border-radius: 0;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: top !important;
  @include media-breakpoint-down(md) {
    background-attachment: scroll !important;
    margin-top:65px;
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .ball {
    background: $paars;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    margin-left: auto;
    margin-top: -540px;
    margin-right: 50px;
    z-index: 999;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
    @include media-breakpoint-down(md) {
      align-self: flex-end;
      margin: 0 auto -100px;
    }
    &:hover {
      background: $oranje;
      h5{
        color: $paars !important;
      }
    }
    .data {
      text-align: center;
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        color: $oranje;
        font-size: 30px;
        line-height: 1;
      }
      p {
        margin-bottom: 0;
        color: white;
        font-weight: 300;
        font-size:18px;
        line-height: 1.5;
      }
    }
  }
  .text-cloud.oranje {
    background: url('./../molenwijck/img/text-cloud.svg');
  }
  .text-cloud.rood {
    background: url('./../molenwijck/img/text-cloud-rood.svg');
  }
  .text-cloud.paars {
    background: url('./../molenwijck/img/text-cloud-paars.svg');
  }
  .text-cloud.groen {
    background: url('./../molenwijck/img/text-cloud-groen.svg');
  }
  .text-cloud.blauw {
    background: url('./../molenwijck/img/text-cloud-blauw.svg');
  }
  .text-cloud {
    background-repeat: no-repeat;
    width: 327px;
    height: 203px;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 50px;
    padding: 20px 45px 20px 25px;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .content {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      color: white;
      width: 327px;
      p {
        margin-bottom: 0;
        line-height: 1.2;
        font-weight: 300;
        font-size: 19px;
      }
      .naam {
        margin-top: 1rem;
        font-size: 14px;
      }
    }
  }
}

section {
  padding: 4rem 0;
  text-align: center;
  background: white;
  @include media-breakpoint-down(md) {
    &.pte {
      h2 {
        padding-top: 4rem;
      }
    }
  }
  h2 {
    font-family: "Playfair Display";
    padding-bottom: 2rem;
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
  }
}

.container.landing {
  background: white;
}

.btn {
  border-radius: 30px;
  padding: 20px;
  min-width: 244px;
  margin-top: 2rem;
  font-size: 16px;
  line-height: 16px;
  &:hover {
    color: white;
  }
}

.btn.oranje:hover {
  background: $paars;
}

.understrap-read-more-link {
  display: none;
}

.more {
  padding-top: 1rem;
}

.oranje, footer {
  background-color: $oranje;
  position: relative;
  h2 {
    color: white;
  }
  .btn {
    background: $paars;
    color: white;
    &:hover {
      background: white;
      color: $paars;
    }
  }
}
.paars {
  background-color: $paars;
  position: relative;
  h2 {
    color: $oranje;
  }
  p {
    color: white;
  }
  .btn {
    background: $oranje;
    color: $body-color;
    &:hover {
      background: white;
    }
  }
}
.blauw {
  background-color: $blauw;
  position: relative;
  h2 {
    color: $oranje;
  }
  p {
    color: white;
  }
  .btn {
    background: $oranje;
    color: $body-color;
    &:hover {
      background: white;
    }
  }
}

.groen {
  background-color: $groen;
  position: relative;
}
.rood {
  background-color: $rood;
  position: relative;
  .btn {
    background: $oranje;
    color: $body-color;
    &:hover {
      background: white;
    }
  }
  h2 {
    color: $oranje;
  }
  p {
    color: white;
  }
}

.klavers::after, footer::after {
  content: "";
  position: absolute;
  top:0; left: 0; width: 100%; height: 100%;
  background: url('../molenwijck/img/klavers.svg');
  background-repeat: repeat;
  mix-blend-mode: color-burn;
  z-index: 1;
  opacity: 0.7;
}

.klavers .container > * {
  z-index: 100;
  position: relative;
  color: white;
  a {
    color: inherit;
    border-bottom: 1px solid white;
    &:hover {
      color: $oranje;
      border-bottom: 1px solid $oranje;
    }
  }
}

.types {
  background: white;
  text-align: left;
  h3 {
    color: $oranje;
    padding-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
    }
  }
  .row {
    padding-bottom: 4rem;
    @include media-breakpoint-up(md) {
      padding-bottom: 2rem;
    }
  }
  .row:nth-of-type(3):before {
    content: 'Driekamer Appartementen';
    position: relative;
    width: 100%;
    margin: 2rem 0;
    font-size: 30px;
    text-align: center;
    font-family: "Playfair Display";
    color: $rood;
  }
  .title {
    color: $rood;
    text-align: center;
    padding-bottom: 2rem;
  }
}

.zoom-item {
  position: relative;
  border-radius: $border-radius-small;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  img {
    max-width: 100%;
    transition: all 0.3s;
    height: 300px;
    object-fit: cover;
    min-width: 100%;
    border-radius: 20px;
      &:hover {
          transform: scale(1.1);
      }
  }
}

.image-and-icon {
  position: relative;
  margin-bottom: 4rem;
  .main-img {
    border-radius: $border-radius-big;
  }
  .icon{
    position: absolute;
    bottom: 0;
    margin-bottom: -50px;
    left: 50%;
    transform: translate(-50%);
  }
}

section.activiteiten {
  padding: 4rem 0 0;
  background: white;
  &:nth-last-of-type(1) {
    padding-bottom: 4rem;
  }
  h2 {
    color: $oranje;
  }
  img {
    border-radius: $border-radius-small;
    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }
  .image-row {
    margin-bottom: 3rem;
  }
  .text-row {
    text-align: left;
    h5{
      color: $oranje;
    }
    ul {
      padding-inline-start: 20px;
      font-weight: 300;
    }
  }
}

.faciliteit {
  // @include media-breakpoint-down(md) {
  //     .col-md-6:last-child {
  //         margin: 2rem auto 0; 
  //     }
  // }
  background: white;
  img {
      border-radius: $border-radius-small;
  }
  h3 {
      padding: 1.5rem 40px 0 40px;
      color: $oranje;
      margin-bottom: 0;
  }
  .desc {
      padding: 0.5rem 0 3rem;
  }
}

.service {
  background: white;
  img {
      border-radius: $border-radius-small;
  }
  h3 {
      padding: 2rem 40px 0 40px;
      color: $oranje;
  }
  .desc {
      padding: 1rem 0 3rem;
  }
}

.page-template-blank {
  .landing {
    @include media-breakpoint-up(lg) {
      padding: 4rem;
    }
    padding: 4rem 0;
    text-align: left;
    .wp-block-image img {
      border-radius:  $border-radius-big;
      margin: 2rem 0;
    }
    h2, h3, h4, h5 {
      color: $oranje;
      padding-bottom: 2rem;
    } 
  }
  .outro {
    background: $oranje;
    &.rood {
      background: $rood;
      h2, a {
        color: $oranje;
      }
    }
    h2 {
      color: white;
    }
    p {
      color: #212529;
    }
    .btn {
      background: $paars;
      color: white;
      &:hover {
        background: white;
        color: $paars;
      }
    }
  }
  blockquote {
      font-style: italic;
      text-align: center;
      color: $rood;
      padding: 20px 10%;
      position: relative;
      p {
          font-size: 30px;
          &:before {
              content: '';
              background: url('../img/quote-flower.svg');
              background-repeat: no-repeat;
              position: absolute;
              top: 22px;
              left: 50%;
              width: 50px;
              height: 50px;
              transform: translate(-50%, -50%);
          }
      }
      &:after {
          content: '';
          height: 2px;
          width: 180px;
          display: block;
          background: $rood;
          margin: 40px auto 0;
      }
      &:before {
          content: '';
          height: 2px;
          width: 180px;
          display: block;
          background: $rood;
          margin: 0 auto 40px;
      }
  }
}

.page-id-234 {
  .outro {
    display: none;
  }
}

.compleet-wonen {
  h2, h5 {
    color: $oranje;
  }
  .lijst {
    padding-top: 2rem;
    ul {
      list-style: none;
      padding-top: 1rem;
      line-height: 1.5;
      font-size: 18px;
      font-weight: 300;
      padding-inline-start: 0;
    }
    img {
      width: 80px;
    }
    h5 {
      min-height: 50px;
    }
  }
  .extra {
    padding-top: 2rem;
    text-align: left;
    p, h5 {
      display: block;
    }
    h5 {
      padding-top: 2rem;
    }
    ul {
      line-height: 1.5;
      font-size: 18px;
      font-weight: 300;
    }
  }
}

.wpcf7-mail-sent-ok {
  border: 2px solid $paars;
  border-radius: 20px;
}

.white-line {
  background:white;
  height: 10px;
}

#intro {
  position: relative;
}

:not(.home) .jumbotron {
  &.fixed {
    margin-top: 290px;
    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
  }
}

.groen.outro {
  h2 {
    color: $oranje;
  }
  .btn {
    background:$oranje;
    color: white;
  }
}

.ervaren {
  background: white;
}

#contact {
  background: white;
  text-align: left;
  .col {
    width: 50%;
    float: left;
    @media screen and (max-width:991px){
      width: 100%;
    }
  }
  .brochure {
    display: flex;
    align-items: center;
    @media screen and (max-width:991px){
      padding-bottom:2rem;
    }
    img {
      height: 50px;
    }
    p {
      text-align: left;
      a {
        color: $paars;
      }
    }
    .col-2 {
      padding:0;
      @media screen and (max-width:991px){
        padding-left:15px;
      }
    }
  }
}

#page {
  overflow-x: hidden;
}

.maps {
  margin: 0;
  background: white;
  iframe {
    width: 100%;
    height: 450px;
    margin: 0;
    transform: translateY(7px);
  }
}