.services, .nieuws, .faciliteiten, .ervaren {
    .col-md-6, .col-lg-4 {
        position: relative;
    }
    h2 {
        color: $oranje;
        padding-bottom: 2rem;
    }
    h3 {
        padding: 2rem 40px 0 40px;
        color: $oranje;
    }
    .desc {
        padding: 1rem 0 6rem;
        @include media-breakpoint-up(md) {
            padding: 1rem 0 10rem; 
        }
    }
    .end {
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.home {
    .services, .nieuws, .faciliteiten, .ervaren {
        @include media-breakpoint-down(md) {
            .col-md-6:last-child {
                margin: 2rem auto 0; 
            }
        }
    }  
}

.nieuws {
    background: white;
    h3 {
        min-height: 104px;
    }
    .desc {
        padding-bottom: 5rem;
    }
}

.faciliteiten .desc {
    padding-bottom: 3rem;
}

.all-news {
    margin: 3rem auto 0; 
}

.ctas {
    position: relative;
    z-index: 99;
    padding:0;
    .ball-wrapper {
        position: absolute;
        top: -225px; left: 50%;
        transform: translateX(-50%);
        display: flex;
        @media screen and (max-width: 750px) {
            display: block;
            position: relative;
            top:0;
            left:0;
            transform: none;
            background: white;
        }
        .ball {
            padding: 0 30px;
            @media screen and (max-width: 750px) {
                padding: 20px 0;
                margin: 0 20px;
                .data {
                    border-radius: 30px;
                }
            }
        }
        p {
            color: white;
            margin-bottom: 0;
        }
        h5 {
            font-size: 25px;
        }
    }
    .data {
        background: $paars;
        color: $oranje;
        border-radius: 50%;
        width:250px; 
        height:250px;
        display: flex;
        padding: 20px;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: 750px) {
            border-radius: 0;
            width: 100%;
            height: auto;
            display: block;
        }
    }
    
}

#video {
    padding: 0;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 991px) {
        padding-top: 65px;
      }
    #videoBG {
    /*not work if the screen ratio is below 16/9*/
        width:100%;     
        height: auto;
        @media screen and (max-width: 991px) {
            width: auto;
            height: 70vh;
          }
    }
    &.fixed {
        margin-top: 290px;
        @media screen and (max-width: 991px) {
            margin-top: 65px;
          }
    }
}

.services {
    background: $oranje;
    margin-top: -10px;
    padding:0;
    .wrapper {
        background: white;
        border-radius: 40px;
        padding: 10px;
        transform: translateY(-90px);
        height: 100%;
    }
    h3 {
        padding: 20px 0;
    }
    .desc {
        padding: 20px 20px 50px 20px;
    }
    a {
        font-size: 18px;
        font-weight: 300;
        // padding-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        .col-md-6:first-child {
            margin-bottom: 2rem;
        }
    }
    
}

#end_quote {
    background: $oranje;
    position: relative;
    .foto {
        img {
            border-radius: 30px;
        }
    }
    .text {
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: left;
        p {
            color: $paars;
            font-size: 35px;
            padding-bottom: 30px;
        }
        p.name {
            font-size: 18px
        }
    }
}

.home {
    #intro {
        padding-bottom: calc(90px + 4rem);
        margin-top: -10px;
    }
    .outro {
        .btn {
            padding: 20px;
        }
    }
}

