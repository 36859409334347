// SCSS Syntax http://sass-lang.com/documentation/file.SASS_REFERENCE.html#css_extensions

// variable declaration in SCSS we use variables to keep style clean and easy to modify
// if we use this variable to say 50 rules and want to change the color, we would change only this variable.

body {
  font-family: "Open Sans";
  font-weight: normal;
  background: $paars;
}

.container {
  @media screen and (min-width: 1400px) {
    max-width: 1240px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1440px;
  }
}

p {
  line-height: 1.5;
  font-size: 18px;
  font-weight: 300;
}

a:hover {
  text-decoration: none;
}

.date {
  font-weight: bold;
  font-size: 14px;
}

.desc {
  font-weight: 300;
  font-size:18px;
}

h2, h3, h4 {
  font-family: "Playfair Display";
}

.more:hover {
  color: $paars;
}

.wpcf7 input[type=submit] {
  padding: 7px;
}