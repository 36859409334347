.ervaren {
    h3 {
        padding: 2rem 20px 0 20px !important;
        color: #f8aa0d;
        min-height: 104px;
    }
    .featured-img {
        position: relative;
        .main {
            border-radius: $border-radius-small;
        }
        .play {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
        }

    }
    & + .outro {
        display: none;
    }
    .desc {
    padding: 1rem 0 5rem !important;
    }
    .col-md-6 {
    margin-bottom: 4rem;
    }
}

.ervaren.beleven {
    h3{
        min-height: 150px;
    }
    & + .outro {
        display: block;
    }
}

.single {
    main {
        padding: 3rem 0;
    }
    .wp-post-image {
        border-radius: $border-radius-small;
    }
    h1 {
        font-size: 30px;
        color: $oranje;
        font-family: "Playfair Display";
        @include media-breakpoint-down(sm){
            padding: 2rem 0;
        }
    }
    .name {
        font-weight: bold;
        font-size: 14px;
    }
    article {
        @media screen and (min-width: 760px) and (max-width: 980px) {
            margin-top: 100px;
        }
    }
    .entry-content {
        padding-top: 2rem;
        p {
            text-align: left;
        }
        blockquote {
            font-style: italic;
            text-align: center;
            color: $rood;
            padding: 20px 10%;
            position: relative;
            p {
                font-size: 30px;
                text-align: center;
                &:before {
                    content: '';
                    background: url('../img/quote-flower.svg');
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 22px;
                    left: 50%;
                    width: 50px;
                    height: 50px;
                    transform: translate(-50%, -50%);
                }
            }
            &:after {
                content: '';
                height: 2px;
                width: 180px;
                display: block;
                background: $rood;
                margin: 40px auto 0;
            }
            &:before {
                content: '';
                height: 2px;
                width: 180px;
                display: block;
                background: $rood;
                margin: 0 auto 40px;
            }
        }
    }
    .post-navigation {
        padding-top: 2rem;
        .btn {
            margin:0;
            float: right;
            background: $oranje;
        }
        .prev,.next {
            line-height: 46px;
            color: $rood;
            font-weight: bold;
        }
        .prev:before {
            content: '';
            background: url('../img/arrows-left.svg');
            background-repeat: no-repeat;
            display: inline-block;
            width: 10px;
            height: 15px;
            margin: 0 15px;
        }
        .next:after {
            content: '';
            background: url('../img/arrows-right.svg');
            background-repeat: no-repeat;
            display: inline-block;
            width: 10px;
            height: 15px;
            margin: 0 15px;
        }
    }
}